import { History } from "types/models/StudyHistory";
import { PaginationParams } from "types/pagination/PaginationParams";
import { PaginationBody } from "types/pagination/PaginationBody";
import api from "api/api";

export const getStudyHistory = async (params: PaginationParams & { study_id?: string }) => {
  const { data } = await api.get(`/api/history/`, { params });
  return data as PaginationBody<History>;
};

export const addStudyHistory = async (history: History) => {
  const { data } = await api.post(`/api/history/`, history);
  return data as History;
};
