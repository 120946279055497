import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BookmarkStudy, Bookmark } from "types/models/Bookmark";
import { getBookmarks } from "api/bookmark";
import { DEFAULT_ROWS_PER_PAGE } from "utils/constants";
import { initStudyDateTime } from "utils/study";
import { logger } from "utils/logger";
import { PaginationParams } from "types/pagination/PaginationParams";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";
import { Study } from "types/models/Study";

export type BookmarkState = BaseState & {
  list: BookmarkStudy[];
  count: number;
  loadedAt: number | null;
};

export const INITIAL_STATE: BookmarkState = {
  ...BASE_INITIAL_STATE,
  list: [],
  count: 0,
  loadedAt: null,
};

export const getBookmarkList = createAsyncThunk<{ studies: BookmarkStudy[]; count: number }, PaginationParams>(
  "bookmark/list",
  async (params = {}) => {
    if (!params.page_size) {
      params.page_size = DEFAULT_ROWS_PER_PAGE;
    }

    const { results: bookmarks, count } = await getBookmarks({ include_related: true, ...params });

    const studies: BookmarkStudy[] = bookmarks.map((bookmark: Bookmark) => ({
      ...initStudyDateTime(bookmark.study as Study),
      bookmarkId: bookmark.id,
    }));

    return { studies, count };
  }
);

export const slice = createSlice({
  name: "bookmark",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBookmarkList.fulfilled, (state, { payload }) => {
      state.list = payload.studies;
      state.count = payload.count;
      state.loadedAt = Date.now();
      state.isLoading = false;
    });

    builder.addCase(getBookmarkList.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getBookmarkList.rejected, state => {
      state.isLoading = false;
      logger.error("Failed to fetch bookmarks");
    });
  },
});

export default slice.reducer;
