import { PaginationBody } from "types/pagination/PaginationBody";
import { PaginationParams } from "types/pagination/PaginationParams";
import { Recording } from "types/models/Recording";
import api from "api/api";

export const createRecording = async (payload: Recording) => {
  const { data } = await api.post("/api/recording/", payload);
  return data;
};

export const getRecordingListWithParams = async (params: PaginationParams) => {
  const { data } = await api.get("/api/recording/", { params });
  return data as PaginationBody<Recording>;
};
