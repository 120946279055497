import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

const PasswordModal = ({ openPopup, children }) => (
  <div>
    <Dialog
      open={openPopup}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: "#191B1E",
          border: "1px #000000",
          borderRadius: "2px",
        },
      }}
    >
      <DialogContent style={{ width: "600px", height: "520px" }}>{children}</DialogContent>
    </Dialog>
  </div>
);

export default PasswordModal;
