import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  userOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  userPhoto: {
    width: "3.5em",
    height: "3.5em",
    minHeight: "3.5em",
    maxHeight: "3.5em",
    maxWidth: "3.5em",
    minWidth: "3.5em",
    borderRadius: "50%",
    color: "#2a2a39",
    fontSize: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
  },
  userName: {
    marginLeft: "1em",
    fontSize: "0.9rem",
    wordBreak: "break-word",
  },
});

export default useStyles;
