import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Checkbox,
  Box,
  Button,
  ListItem,
  ListItemText,
  List,
  ListSubheader,
  ListItemSecondaryAction,
  createStyles,
  makeStyles,
  IconButton,
} from "@material-ui/core";

import { useDispatch } from "react-redux";
import { useAuth } from "state/selector";
import { ProcedureGroups } from "types/models/ProcedureGroups";
import { InsuranceGroups } from "types/models/InsuranceGroups";
import { getProcedureGroups } from "api/procedureGroups";
import { getInsuranceGroups } from "api/insuranceGroups";
import { updateUser } from "api/user";
import { User } from "types/models/User";
import { getUser } from "state/reducers/auth";
import { HighlightOff, Visibility, VisibilityOff } from "@material-ui/icons";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    closeBtn: {
      position: "absolute",
      top: 0,
      right: 0,
      margin: "0.25rem",
      zIndex: 10,
      color: `${theme.palette.error.main}`,
    },
    procedure_subheader: {
      backgroundColor: `${theme.palette.primary.dark_opaque}`,
      borderRadius: "6px",
    },
    listItem: {
      marginBottom: "-0.5rem",
    },
  })
);

const UserPreferences = ({ handleClose }: { handleClose: () => void }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [tab, setTab] = useState(0);
  const [groupData, setGroupData] = useState<{
    procedure_groups: ProcedureGroups[];
    insurance_groups: InsuranceGroups[];
  }>();
  const [procedureGroups, setProcedureGroups] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      setProcedureGroups(user.procedure_groups);
    }
  }, [user]);

  useEffect(() => {
    const getGroupData = async () => {
      const { results: procedure_groups } = await getProcedureGroups();
      const { results: insurance_groups } = await getInsuranceGroups();
      setGroupData({ procedure_groups, insurance_groups });
    };

    getGroupData();
  }, []);

  if (!user || !groupData) return null;

  return (
    <Box display="flex" flexDirection="column" marginBottom="1rem">
      <IconButton
        className={classes.closeBtn}
        onClick={() => {
          dispatch(getUser());
          handleClose();
        }}
      >
        <HighlightOff />
      </IconButton>
      <Tabs
        value={tab}
        onChange={(e, t) => setTab(t)}
        indicatorColor="primary"
        textColor="primary"
        style={{ marginBottom: "0.5rem", marginTop: "-1rem" }}
      >
        <Tab label="Procedures" />
        <Tab label="Insurances" />
      </Tabs>
      {tab === 0 && (
        <List
          subheader={
            <ListSubheader className={classes.procedure_subheader}>
              Select the Procedure Groups you wish to read for
            </ListSubheader>
          }
          style={{ maxHeight: "350px", overflow: "auto" }}
        >
          {groupData.procedure_groups.map(p => (
            <ListItem
              key={p.id}
              role={undefined}
              dense
              button
              onClick={() => {
                const newProcedureGroups = procedureGroups.includes(p.id)
                  ? procedureGroups.filter(id => id !== p.id)
                  : [...procedureGroups, p.id];
                setProcedureGroups(newProcedureGroups);
                updateUser((user as User).id, { procedure_groups: newProcedureGroups });
              }}
              className={classes.listItem}
            >
              <ListItemText id={p.id} primary={p.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={procedureGroups.includes(p.id)}
                  tabIndex={-1}
                  icon={<VisibilityOff />}
                  checkedIcon={<Visibility />}
                  disableRipple
                  inputProps={{ "aria-labelledby": p.id }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {tab === 1 && (
        <List
          dense
          subheader={
            <ListSubheader className={classes.listItem}>Insurances you are authorized to read for</ListSubheader>
          }
        >
          {user.insurance_groups.map(id => {
            const insuranceGroup = groupData.insurance_groups.find(g => g.id === id);
            return (
              <ListItem key={id} className={classes.listItem}>
                <ListItemText key={insuranceGroup?.id} primary={insuranceGroup?.name} />
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default UserPreferences;
