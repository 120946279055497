import React, { FC, MouseEvent } from "react";
import { Box, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import useStyles from "styles/studyListStyles";
import { useStudy } from "state/selector";

const BUTTON_CHOICES = [
  { value: "bookmark", label: "Bookmarks" },
  { value: "history", label: "History" },
];

export const StudyMenuButtons: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();
  const { isLoading } = useStudy();

  const onChange = (newValue: string) => {
    navigate(`/studies/${newValue}`);
  };

  const page = location.pathname.split("/").slice(-1);

  return (
    <Box data-testid="study-menu-buttons">
      <ToggleButtonGroup
        value={page}
        exclusive
        onChange={(e: MouseEvent<HTMLElement>, newValue: string) => onChange(newValue)}
        size="small"
        className={classes.studyHistoryToggleButtonGroup}
      >
        {BUTTON_CHOICES.map(({ label, value }) => (
          <ToggleButton
            className={classes.studyHistoryToggleButton}
            value={value}
            aria-label={value}
            key={value}
            disabled={isLoading}
            data-testid={`study-menu-button-${value}`}
          >
            <Typography className={classes.studyStatusSummary} variant="subtitle2" gutterBottom>
              {label}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
