import { makeStyles } from "@material-ui/core/styles";
import { convertOpacityIntoHexString } from "styles/util";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  // Report Page
  reportRoot: {
    width: "100%",
    height: "calc(100% - 55px)",
    padding: "1.5rem 0",
    display: "flex",
    flexDirection: "column",
  },
  reportContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  noteSection: {
    flex: 1,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  noteLabelRow: {
    borderBottom: `1px solid ${theme.palette.common.white}${convertOpacityIntoHexString(0.13)} `,

    "&.extended": {
      borderBottom: "none",
    },
  },
  extendButton: {
    color: `${theme.palette.primary.active}`,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "30px",

    "& svg": {
      width: "22.5px",
      height: "22.5px",
    },

    "&.extended": {
      "& svg": {
        width: "17.5px",
      },
    },
  },
  reportInputRow: {
    height: "45vh",
    minHeight: "10vh",
    marginBottom: "1.5em",
    width: "100%",
  },
  reportInput: {
    border: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    padding: "8px",
    color: `${theme.palette.common.bright}`,
    resize: "none",
    outline: "none",
  },
  noteTextRow: {
    height: "17vh",
  },
  exportBtnRow: {
    width: "100%",
    marginBottom: "1.5em",
    marginTop: "1.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonGroup: {
    display: "flex",
    gap: "1em",
  },
  reportBtn: {
    padding: "0.75em 1em 0.75em 1em !important",
    borderRadius: "7px",
    border: `1px solid ${theme.palette.primary.active} !important`,
    fontSize: "0.85em",
  },
  reportBtnActive: {
    padding: "0.75em 1em 0.75em 1em !important",
    borderRadius: "7px",
    border: `1px solid ${theme.palette.primary.active} !important`,
    fontSize: "0.85em",
    opacity: 0.5,
    cursor: "not-allowed",
  },
  reportStudyList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  patientNameRow: {
    marginTop: "0.25em",
    marginBottom: "0.25em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  editLabel: {
    color: `${theme.palette.error.dark}`,
    fontWeight: "bold",
    fontSize: "1em",
    marginLeft: "1em",
  },
  savingLabel: {
    color: `${theme.palette.primary.active}`,
    fontWeight: "lighter",
  },
  reportValue: {
    fontSize: "1em",
    color: `${theme.palette.common.main}`,
  },
  spacing: {
    margin: "0 auto",
  },
  noMargin: {
    marginBottom: 0,
  },
  studyItemRow: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "1.25em 1.5em 1.25em 1.5em",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
  studyItemExtended: {
    backgroundColor: `${theme.palette.other.dark} !important`,
    borderBottom: "none !important",
  },
  studyItemActive: {
    border: `1px solid ${theme.palette.other.light}${convertOpacityIntoHexString(0.5)} !important`,
  },
  studyItemLabel: {
    fontSize: "1em",
    color: `${theme.palette.other.light}`,
    cursor: "pointer",
  },
  seriesList: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.common.white}${convertOpacityIntoHexString(0.13)} `,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  seriesRow: {
    display: "flex",
    flexDirection: "row",
    padding: "0.65em 1.5em 0.65em 1.5em",
    cursor: "pointer",
  },
  seriesImage: {
    width: "20%",
    "& img": {
      borderRadius: "10px",
      maxHeight: "6vw",
      width: "100%",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  seriesInfo: {
    marginLeft: "2.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: `${theme.palette.other.light}`,
    fontWeight: 500,
    fontSize: "1em",
  },
  seriesNoRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0.35em",
  },
  seriesNoLabel: {
    color: `${theme.palette.primary.main}`,
  },
  seriesNo: {
    marginRight: "1em",
  },
  seriesDescription: {
    color: `${theme.palette.common.main}`,
    marginBottom: "0.35em",
    "&:hover": {
      textDecoration: "underline",
      textUnderlineOffset: "0.35em",
    },
  },
  patientJacketDialogStudyItemRow: {
    cursor: "auto",
  },
  patientJacketDialogStudyItemDateLabel: {
    margin: "0.5em 0 0",
    color: `${theme.palette.common.main}`,
  },
  patientJacketDialogStudyItemDescription: {
    margin: "0 auto",
    "&:hover": {
      textDecoration: "underline",
      textUnderlineOffset: "0.25em",
    },
  },
  modalityIcon: {
    background: `${theme.palette.secondary.light}`,
    verticalAlign: "middle",
    width: "3.75em",
    height: "2.5em",
    borderRadius: "10px",
    marginRight: "1em",
  },
  reportIcon: {
    cursor: "pointer",
    color: `${theme.palette.other.light}`,
    width: "0.95em",
    height: "1.35em",
    marginRight: "0.5em",
  },
  instancesNoLabel: {
    width: "2.5em",
  },
}));

export default useStyles;
