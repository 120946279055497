import { PaginationBody } from "types/pagination/PaginationBody";
import { User } from "types/models/User";
import api from "api/api";

export const getUsers = async () => {
  const { data } = await api.get(`/api/users/`);
  return data as PaginationBody<User>;
};

export const updateUser = async (userId: string, payload: Partial<User>) => {
  const { data } = await api.put(`/api/users/${userId}/`, payload);
  return data as User;
};

export const getMe = async () => api({ url: `/api/users/me`, validateStatus: null });
