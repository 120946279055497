import { AnyObject } from "types/AnyObject";

export function getExtensionId(): string {
  const extensionIdEl = document.getElementById("nl-extension-id") as HTMLInputElement;
  return extensionIdEl ? extensionIdEl.value : "";
}

export function sendExtensionMessage(params: AnyObject) {
  console.log("send extension message");
  const extensionId = getExtensionId();

  if (extensionId) {
    chrome.runtime.sendMessage(extensionId, params);
    return true;
  }

  return false;
}

export function checkExtension() {
  const extensionId = getExtensionId();
  if (extensionId) {
    return true;
  }
  return false;
}
