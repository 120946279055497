import { combineReducers } from "@reduxjs/toolkit";

import auth from "state/reducers/auth";
import study from "state/reducers/study";
import bookmark from "state/reducers/bookmark";
import studyHistory from "state/reducers/studyHistory";
import hangingProtocol from "state/reducers/hangingProtocol";
import metaData from "state/reducers/metaData";

const reducers = combineReducers({
  auth,
  study,
  bookmark,
  studyHistory,
  hangingProtocol,
  metaData,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
