import { makeStyles } from "@material-ui/core/styles";
import { convertOpacityIntoHexString } from "styles/util";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  hangingProtocolEditRoot: {
    width: "100%",
    height: "100%",
    paddingTop: "30px",
  },
  hangingProtocolEdit: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  hangingProtocolEditTopRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2em",
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  hangingProtocolTitle: {
    fontSize: "1.75em",
  },
  hangingProtocolListActionButton: {
    padding: "0.75em 1em 0.5em 1em !important",
    borderRadius: "6px",
    border: `1px solid ${theme.palette.primary.active} !important`,
    fontSize: "0.65em",
    fontWeight: "bold",
    marginLeft: "10px",
    minWidth: "auto",
    color: theme.palette.common.bright,
    "& .MuiButton-startIcon": {
      marginTop: "-1px",
    },
  },
  hangingProtocolEditRow: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    paddingTop: "3em",
    paddingBottom: "3em",
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  hangingProtocolViewportRow: {
    borderBottom: "none",
  },
  hangingProtocolEdidTitleCol: {
    width: "35%",
  },
  hangingProtocolEditCol: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    background: `${theme.palette.other.dark}`,
    padding: "2.5em 2em 2.5em 2em",
    borderRadius: "1em",
  },
  hangingProtocolEditInputRow: {
    width: "100%",
    marginTop: "2.25em",
    display: "flex",
    "&:first-child": {
      marginTop: "0em",
    },
  },
  hangingProtocolEditInput: {
    width: "48%",
    marginRight: "2em",
    "& .MuiInputBase-root": {
      fontSize: "0.85rem",
      paddingRight: "0 !important",
      "& input.MuiInputBase-input": {
        padding: "4.5px 6px !important",
      },
    },
    "&:last-child": {
      marginRight: "0em",
    },
    "&.hideBorderBottom": {
      "& fieldset": {
        borderBottom: "none",
      },
    },
  },
  hangingProtocolEditTitle: { fontSize: "1.2em", color: theme.palette.common.bright },
  gridMatrixLabel: {
    fontSize: "0.75rem",
    color: `${theme.palette.common.white}${convertOpacityIntoHexString(0.7)} !important`,
    marginTop: "-1.5em",
  },
  gridMatrixWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "8em",
    marginRight: "1em",
  },
  gridMatrixCol: {
    width: "2em",
    height: "2em",
    cursor: "pointer",
    border: `1px solid ${theme.palette.common.white}${convertOpacityIntoHexString(0.23)}`,
    "&:nth-child(1)": {
      borderTopLeftRadius: "3.5px",
    },
    "&:nth-child(4)": {
      borderTopRightRadius: "3.5px",
    },
    "&:nth-child(13)": {
      borderBottomLeftRadius: "3.5px",
    },
    "&:nth-child(16)": {
      borderBottomRightRadius: "3.5px",
    },
  },

  gridMatrixColSelected: {
    background: `${theme.palette.other.light}`,
    color: `${theme.palette.common.white}`,
  },
  gridMatrixContainer: {
    width: "100%",
  },
  buttonGroupContainer: {
    width: "100%",
  },
  gridMatrixMain: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    marginTop: "0.25em",
  },
  gridMatrixHint: {
    color: `${theme.palette.common.dark}`,
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    fontSize: "0.75em",
    fontWeight: "bold",
  },
  gridMatrixHintIcon: {
    marginRight: "0.25em",
  },
  hangingProtocolViewportTitle: {
    width: "48%",
    fontSize: "0.85em",
    fontWeight: "bold",
    color: theme.palette.primary.active,
    marginRight: "2em",
    "&:last-child": {
      marginRight: "0em",
    },
  },
  buttonGroupCol: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "3em",
    color: theme.palette.primary.active,
    fontSize: "0.75em",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "uppercase",
    border: `1px solid ${theme.palette.primary.active}${convertOpacityIntoHexString(0.5)}`,
    "&:nth-child(1)": {
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    },
  },
  buttonGroupColSelected: {
    background: `${theme.palette.other.light}`,
    color: `${theme.palette.common.white}`,
  },
  hangingProtocolViewPortScalingSlide: {
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.primary.active,
    },
    "& .MuiSlider-rail": {
      backgroundColor: `${theme.palette.primary.active}${convertOpacityIntoHexString(0.5)}`,
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.primary.active,
    },
  },
  viewPortGridContainer: {
    width: "100%",
    marginTop: "1em",
  },
  hangingProtocolViewportClearButton: {
    color: `${theme.palette.common.bright}`,
    border: `1px solid ${theme.palette.secondary.light} !important`,
    marginLeft: 0,
    marginTop: "11em",
  },
}));

export default useStyles;
