import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHangingProtocolListWithParams } from "api/hangingProtocol";
import { DEFAULT_ROWS_PER_PAGE } from "utils/constants";
import { logger } from "utils/logger";
import { HangingProtocol } from "types/models/HangingProtocol";
import { PaginationParams } from "types/pagination/PaginationParams";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";

export type HangingProtocolState = BaseState & {
  list: HangingProtocol[];
  count: number;
};

export const INITIAL_STATE: HangingProtocolState = {
  ...BASE_INITIAL_STATE,
  list: [],
  count: 0,
};

export const getHangingProtocolList = createAsyncThunk<
  { hangingProtocols: HangingProtocol[]; count: number },
  PaginationParams
>("hangingProtocol/list", async (params = {}) => {
  if (!params.page_size) {
    params.page_size = DEFAULT_ROWS_PER_PAGE;
  }

  const { results: hangingProtocols, count } = await getHangingProtocolListWithParams(params);

  return { hangingProtocols, count };
});

export const slice = createSlice({
  name: "hangingProtocol",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHangingProtocolList.fulfilled, (state, { payload }) => {
      state.list = payload.hangingProtocols;
      state.count = payload.count;
      state.isLoading = false;
    });

    builder.addCase(getHangingProtocolList.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getHangingProtocolList.rejected, state => {
      state.isLoading = false;
      logger.error("Failed to fetch hanging protocols");
    });
  },
});

export default slice.reducer;
