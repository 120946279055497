import React, { FC } from "react";

import cn from "classnames";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import useStyles from "styles/hangingProtocolEditStyles";
import { AutoCompleteViewOption } from "types/AutoCompleteViewOption";
import { CSSProperties } from "@material-ui/styles";

type AutoCompleteViewProps = {
  options: AutoCompleteViewOption[];
  onChange: (value: undefined | string | string[]) => void;
  renderOption?: (option: AutoCompleteViewOption) => React.ReactNode;
  renderTags?: (values: AutoCompleteViewOption[]) => React.ReactNode;
  multiple?: boolean;
  showPlaceholder?: boolean;
  freeSolo?: boolean;
  label?: string;
  value?: string;
  hideBottomBorder?: boolean;
  disabled?: boolean;
  customStyles?: CSSProperties;
};

const AutoCompleteView: FC<AutoCompleteViewProps> = ({
  options,
  onChange,
  multiple,
  renderOption,
  renderTags,
  showPlaceholder,
  freeSolo,
  label = "",
  value = "",
  hideBottomBorder = true,
  disabled = false,
  customStyles = {},
}) => {
  const classes = useStyles();

  const singleSelectionValue = !multiple ? options.find(option => value === option.value) : undefined;

  return (
    <Autocomplete<AutoCompleteViewOption, boolean, boolean, boolean>
      data-testid="auto-complete-view"
      size="small"
      multiple
      disabled={disabled}
      className={cn(classes.hangingProtocolEditInput, { hideBorderBottom: singleSelectionValue && hideBottomBorder })}
      style={customStyles}
      options={options}
      ChipProps={{ className: "custom-autocomplete-chip" }}
      onChange={(_, changedOptions: any) =>
        multiple
          ? onChange(changedOptions.map((o: AutoCompleteViewOption) => o.value))
          : onChange(changedOptions.length > 1 ? changedOptions[1].value : (changedOptions.length > 0 ? changedOptions[0].value : undefined))
      }
      value={
        multiple
          ? options.filter(option => value?.includes(option.value))
          : options.filter(option => value === option.value)
      }
      getOptionLabel={option => option.label}
      renderOption={renderOption}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          data-testid="auto-complete-view-text"
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={(showPlaceholder && singleSelectionValue?.label) || ""}
        />
      )}
      renderTags={renderTags}
      freeSolo={freeSolo}
      limitTags={!multiple ? 1 : -1}
    />
  );
};

export default AutoCompleteView;
