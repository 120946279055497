import React, { FC, ChangeEvent, KeyboardEvent, useState, FocusEvent } from "react";
import TextField from "@material-ui/core/TextField";
import { FormControl, Select, MenuItem, IconButton, InputAdornment, Tooltip, makeStyles } from "@material-ui/core";
import { useMetaData } from "state/selector";
import { useApp } from "hooks/useApp";
import { SEARCH_FILTER_ICONS } from "utils/icons";

const useStyles = makeStyles({
  input: {
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

type SearchFilterProps = {
  field: string;
  searchType: "contains" | "gt" | "lt" | "match";
  label?: string;
  inputType?: "search" | "text" | "dropdown" | "number";
};

const SearchFilter: FC<SearchFilterProps> = ({ field, searchType, label = "", inputType = "text" }) => {
  const classes = useStyles();
  const {
    navigation: { query, processChange },
  } = useApp();
  const metaData = useMetaData();

  let queryParam = field;

  if (searchType === "contains") {
    queryParam += "__icontains";
  } else if (searchType === "gt") {
    queryParam += "__gt";
  } else if (searchType === "lt") {
    queryParam += "__lt";
  }

  const [value, setValue] = useState(query[queryParam] || "");

  const processFieldChange = (newValue: string) => {
    processChange(queryParam, newValue);
  };

  const clearValue = () => {
    setValue("");
    processFieldChange("");
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      processFieldChange((event.target as HTMLInputElement).value);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    processFieldChange((event.target as HTMLInputElement).value);
  };

  const menuItems =
    metaData.priority.map((choice: string[]) => (
      <MenuItem key={choice[0]} value={choice[0]}>
        {choice[1].toUpperCase()}
      </MenuItem>
    )) || [];

  return (
    <FormControl size="small" variant="outlined" key={field}>
      {inputType === "search" && (
        <TextField
          data-testid="search-filter-search"
          placeholder="Search"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Search">
                  <SEARCH_FILTER_ICONS.Search fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={!value} onClick={() => clearValue()}>
                  <SEARCH_FILTER_ICONS.ResetSearch fontSize="small" aria-label="clear" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {inputType === "text" && (
        <TextField
          id={field}
          data-testid="search-filter-text"
          label={label}
          size="small"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          variant="outlined"
        />
      )}

      {inputType === "dropdown" && (
        <Select
          data-testid="search-filter-dropdown"
          id={field}
          label={label}
          value={value}
          onChange={event => processFieldChange(event.target.value as string)}
          defaultValue=""
          MenuProps={{ className: "custom-dropdown-menu" }}
        >
          <MenuItem key="" value="">
            CLEAR CHOICE
          </MenuItem>
          {menuItems}
        </Select>
      )}

      {inputType === "number" && (
        <TextField
          data-testid="search-filter-number"
          className={classes.input}
          variant="outlined"
          size="small"
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: searchType === "lt" && (
              <InputAdornment position="end">
                <SEARCH_FILTER_ICONS.ArrowBackIos fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: searchType === "gt" && (
              <InputAdornment position="end">
                <SEARCH_FILTER_ICONS.ArrowForwardIos fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
    </FormControl>
  );
};

export default SearchFilter;
