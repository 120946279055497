import { useSelector } from "react-redux";
import { RootState } from "state/reducers";
import { AuthState } from "state/reducers/auth";
import { MetaDataState } from "state/reducers/metaData";
import { StudyState } from "state/reducers/study";

export const auth = (state: RootState): AuthState => state.auth;
export const study = (state: RootState): StudyState => state.study;
export const bookmark = (state: RootState) => state.bookmark;
export const metaData = (state: RootState): MetaDataState => state.metaData;
export const hangingProtocol = (state: RootState) => state.hangingProtocol;
export const studyHistory = (state: RootState) => state.studyHistory;

export const useAuth = (): AuthState => useSelector(auth);
export const useStudy = (): StudyState => useSelector(study);
export const useBookmark = () => useSelector(bookmark);
export const useMetaData = (): MetaDataState => useSelector(metaData);
export const useHangingProtocol = () => useSelector(hangingProtocol);
export const useStudyHistory = () => useSelector(studyHistory);
