import moment from "moment";
import humanizeDuration from "humanize-duration";
import { Study } from "types/models/Study";

export function initStudyDateTime(study: Study) {
  study.date = moment(study.date).format("ll");

  if (study.patient) {
    study.patient.date_of_birth = moment(study.patient.date_of_birth).format("ll");
  }

  if (study.deadline) {
    const deadline_time = moment(study.deadline);
    const current_time = moment();
    const diff = deadline_time.diff(current_time);
    study.due = shortEnHumanizer(diff);
    if (diff < 0) {
      study.due = `${study.due} ago`;
    }
  }

  return study;
}

export const shortEnHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  largest: 2,
  delimiter: " ",
  spacer: "",
});