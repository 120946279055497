import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

type StyledBadgeProps = {
  background: string;
  variant: string;
};

export const StyledBadge: React.FC<StyledBadgeProps> = withStyles(() => ({
  badge: {
    position: "static",
    transform: "none",
    marginRight: "8px",
    backgroundColor: props => props.background,
  },
}))(Badge) as React.FC<StyledBadgeProps>;
