import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStudyHistory } from "api/studyHistory";
import { DEFAULT_ROWS_PER_PAGE } from "utils/constants";
import { initStudyDateTime } from "utils/study";
import { logger } from "utils/logger";
import { PaginationParams } from "types/pagination/PaginationParams";
import { StudyHistory, History } from "types/models/StudyHistory";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";

export type StudyHistoryState = BaseState & {
  list: StudyHistory[];
  count: number;
  loadedAt: number | null;
};

export const INITIAL_STATE: StudyHistoryState = {
  ...BASE_INITIAL_STATE,
  list: [],
  count: 0,
  loadedAt: null,
};

export const getStudyHistoryList = createAsyncThunk<
  { studies: StudyHistory[]; count: number },
  PaginationParams & { study_id?: string }
>("studyHistory/list", async (params = {}) => {
  if (!params.page_size) {
    params.page_size = DEFAULT_ROWS_PER_PAGE;
  }

  if (!params.ordering) {
    params.ordering = "-last_opened";
  }

  const { results: histories, count } = await getStudyHistory({ include_related: true, ...params });

  const studies = histories.map((history: History) => ({
    ...initStudyDateTime(history.study),
    historyId: history.id,
    historyUser: history.user.name,
    historyUserId: history.user.id,
    historyUserProfile: history.user.profile_picture,
    historyAccessed: history.last_opened,
  }));

  return { studies, count };
});

export const slice = createSlice({
  name: "studyHistory",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStudyHistoryList.fulfilled, (state, { payload }) => {
      state.list = payload.studies;
      state.count = payload.count;
      state.loadedAt = Date.now();
      state.isLoading = false;
    });

    builder.addCase(getStudyHistoryList.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getStudyHistoryList.rejected, state => {
      state.isLoading = false;
      logger.error("Failed to fetch study histories");
    });
  },
});

export default slice.reducer;
