import { makeStyles } from "@material-ui/core/styles";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  patientJacketDialog: {
    "& .MuiDialog-container .MuiPaper-root": {
      maxWidth: 650,
      background: `${theme.palette.secondary.dark}`,
    },
    "& .MuiDialogTitle-root": {
      padding: "1.5em",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
      maxHeight: "60vh",
    },
    "& .MuiDialogActions-root": {
      padding: "1.5em",
    },
  },
  title: {
    color: `${theme.palette.common.main}`,
  },
}));

export default useStyles;
