import React, { FC } from "react";
import { Typography } from "@material-ui/core";

import useStyles from "styles/studyListStyles";

type StudyStatusSummaryProps = { label: string };

export const StudyStatusSummary: FC<StudyStatusSummaryProps> = ({ label }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.studyStatusSummary} variant="subtitle2" gutterBottom>
      <span data-testid="study-status-summary-label">{label}</span>
    </Typography>
  );
};
