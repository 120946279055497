import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { IconButton, Grid } from "@material-ui/core";
import { getPredefinedColor } from "utils/users";
import { PhotoCamera } from "@material-ui/icons";
import UserProfileBox from "components/UserProfile/userProfileBox/userProfileBox";

import useStyles from "styles/userProfileStyles";
import { updateUser } from "api/user";
import { getUser } from "state/reducers/auth";
import { useAuth } from "state/selector";

const UserProfileContent = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userProfilePicture = user.profile_picture;
  const profilePhotoInputRef = useRef(null);

  const onChangeProfilePhotoFile = async e => {
    e.stopPropagation();
    e.preventDefault();
    const formData = new FormData();

    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      formData.append("profile_picture", file, file.name);
      await updateUser(user.id, formData);
      dispatch(getUser());
    }
  };

  const handleProfilePhotoClick = () => profilePhotoInputRef.current?.click();

  return (
    <div className={classes.profileContainer}>
      <Grid container spacing={2}>
        <Grid container item xs={6} alignItems="center" justify="center" className={classes.customGrid}>
          <input
            type="file"
            ref={profilePhotoInputRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
            onChange={onChangeProfilePhotoFile}
          />
          <div
            className={cn(classes.profilePhoto, classes.profileMenuPhoto)}
            style={{
              background: `${userProfilePicture ? `url(${userProfilePicture})` : getPredefinedColor(user.id)}`,
            }}
          >
            {!userProfilePicture}
            <div className={classes.textFieldContainer}>
              <IconButton
                color="primary"
                component="span"
                className={classes.profilePhotoIconButton}
                onClick={handleProfilePhotoClick}
              >
                <PhotoCamera />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <UserProfileBox onClose={handleClose} />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserProfileContent;
