import axios from "axios";
import config from "config";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const api = axios.create({
  baseURL: config.apiBaseURL,
  withCredentials: config.withCredentials,
});

export default api;
