import { ThemeOptions } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const defaultPalette = {
  type: "dark",
  primary: {
    dark: "rgba(30,41,59,.5)",
    dark_opaque: "rgba(30,41,59,1)",
    main: "#234a49",
    active: "#9FA8DA",
    light: "#cae2dc",
  },
  secondary: {
    dark: "#0F172A",
    main: "#1A2667",
    active: "#0F1012",
    light: "#475569", // for tag
  },
  common: {
    dark: "#726F7E",
    main: "#F7F7F7",
    active: "#515151",
    light: "#A19FAD",
    bright: "#D0D0D0",
    white: "#FFFFFF",
  },
  shadows: {
    dark: "#5E6267",
  },
  other: {
    dark: "#131416",
    light: "#9ECBF1",
    black: "#000000",
  },
};

export const createThemeOptions = (palette: any) =>
  ({
    palette: palette as PaletteOptions,
    overrides: {
      MuiSelect: {
        selectMenu: {
          whiteSpace: "break-spaces",
        },
      },
    },
  } as ThemeOptions);

export const defaultThemeOptions = createThemeOptions(defaultPalette);
