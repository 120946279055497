import React, { FC, Suspense } from "react";
import { Box } from "@material-ui/core";

import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "components/NavBar/NavBar";
import { ROUTE_PATH_LIST, ROUTE_COMPONENT_LIST } from "routes/routes";

const AppRoutes: FC = () => (
  <Suspense fallback={<div />}>
    <Routes>
      {ROUTE_PATH_LIST.map(({ path, name }) => (
        <Route
          key={name}
          path={path}
          element={
            <Box flexDirection="column" display="flex" height="100vh">
              <NavBar />
              {React.createElement(ROUTE_COMPONENT_LIST[name])}
            </Box>
          }
        />
      ))}
      <Route path="/" element={<Navigate to="/studies?page=1&status=ready&ordering=-due" />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
