import config from "config";

export const redirectLogin = () => {
  let url = `${config.apiBaseURL}/accounts/login`;

  if (window.location.pathname !== "/") {
    url = `${url}?next=${window.location.pathname}`;
  }

  window.location.replace(url);
};

export const redirectLogout = () => {
  window.location.replace(`${config.apiBaseURL}/accounts/logout/`);
};
