import React, { createContext, useContext, useEffect, useState } from "react";
import { ProviderProps } from "types/ProviderProps";
import { createThemeOptions, defaultThemeOptions } from "styles/theme";
import { AppTheme } from "types/AppTheme";
import { createTheme } from "@material-ui/core/styles";
import { getOrganizationConfig } from "api/organizationConfig";

type AppThemeContextType = {
  theme: AppTheme;
  logoURL?: string;
};

const getDefaultTheme = () => createTheme(defaultThemeOptions) as AppTheme;

const defaultTheme = getDefaultTheme();

const AppThemeContext = createContext<AppThemeContextType>({
  theme: defaultTheme,
});

export const useAppTheme = () => useContext(AppThemeContext);

export const AppThemeProvider = ({ children }: ProviderProps) => {
  const [theme, setTheme] = useState<AppTheme>();
  const [logoURL, setLogoURL] = useState<string>();

  useEffect(() => {
    getOrganizationConfig()
      .then(config => {
        const { colors } = config;
        if (Object.keys(colors).length === 0) {
          setTheme(defaultTheme);
        } else {
          const themeOptions = createThemeOptions(colors);
          const appTheme = createTheme(themeOptions) as AppTheme;
          setTheme(appTheme);
        }

        setLogoURL(config.logo);
      })
      .catch(err => {
        console.error(err);
        setTheme(defaultTheme);
      });
  }, []);

  if (!theme) {
    return <></>;
  }

  return <AppThemeContext.Provider value={{ theme, logoURL }}>{children}</AppThemeContext.Provider>;
};
