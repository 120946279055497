import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMe } from "api/user";
import { redirectLogin } from "utils/auth";
import { User } from "types/models/User";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";

export type AuthState = BaseState & {
  user: User | null;
};

export const INITIAL_STATE: AuthState = {
  ...BASE_INITIAL_STATE,
  user: null,
};

export const getUser = createAsyncThunk<User>("auth/get", async (params, { rejectWithValue }) => {
  const res = await getMe();

  if (res.status === 401 || res.status === 403) {
    return rejectWithValue(false);
  }

  return res.data;
});

const slice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    });

    builder.addCase(getUser.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getUser.rejected, state => {
      state.isLoading = false;
      redirectLogin();
    });
  },
});

export default slice.reducer;
