import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import "sweetalert2/src/sweetalert2.scss";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import AppRoutes from "routes";
import AuthGuard from "components/Auth/AuthGuard";
import { AppProvider } from "hooks/useApp";
import { BrowserRouter } from "react-router-dom";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import { useAppTheme } from "providers/AppThemeProvider";
import { useAuth } from "state/selector";

const App = () => {
  const { theme } = useAppTheme();
  const { user } = useAuth();

  // Set user on Sentry
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, name: user.name, email: user.email });
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthGuard>
        <BrowserRouter>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
          <Toaster
            position="bottom-right"
            toastOptions={{
              style: {
                background: "#0F172A",
                color: "white",
              },
              success: { duration: Infinity },
              error: { duration: Infinity },
            }}
          >
            {t => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== "loading" && (
                      <div
                        style={{ width: "100%", height: "100%", position: "absolute", cursor: "pointer" }}
                        onClick={() => toast.dismiss(t.id)}
                      />
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
        </BrowserRouter>
      </AuthGuard>
    </ThemeProvider>
  );
};

export default App;
