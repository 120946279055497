import { makeStyles } from "@material-ui/core/styles";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  profilePhoto: {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    color: "#2a2a39",
    fontSize: "0.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
  },

  profilePhotoIconButton: {
    position: "absolute",
    left: "3em",
    top: "3.5em",
    bottom: 0,
    padding: 0,
    color: `${theme.palette.primary.active}`,
    background: "white !important",
    width: "2em",
    height: "2em",
    "& svg": {
      width: "1em",
      height: "1em",
    },
  },

  customGrid: {
    height: "500px",
  },

  reportBtn: {
    padding: "0.75em 1em 0.75em 1em !important",
    borderRadius: "7px",
    border: `1px solid ${theme.palette.primary.active} !important`,
    fontSize: "0.85em",
    height: "100%",
  },

  textField: {
    width: "100%",
    marginBottom: "4em",
  },

  secondaryTextField: {
    width: "100%",
    marginBottom: "3em",
  },

  phoneNumberTextField: {
    width: "100%",
    marginBottom: "2em",
  },

  passwordButton: {
    marginBottom: "2em",
  },

  spanEmailField: {
    position: "absolute",
    left: "1em",
    top: "5em",
  },

  spanPhoneField: {
    position: "absolute",
    right: "23em",
    top: "5em",
  },

  spanPasswordField: {
    position: "absolute",
    left: "1em",
    top: "5em",
  },
  textFieldContainer: {
    position: "relative",
  },
  profileContainer: {
    marginTop: theme.spacing(0.75),
  },
  bottomDiv: {
    marginBottom: "1.5em",
  },
}));

export default useStyles;
