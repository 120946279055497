/* global chrome */

import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Tooltip } from "@material-ui/core";

import { NL_EXTENSION_EVENTS } from "utils/constants";
import { useAppTheme } from "providers/AppThemeProvider";
import { ReactComponent as NewLanternLogoIcon } from "../../assets/icons/new-lantern-logo.svg";

const NAV_SECTIONS = [];

const LeftNavBarLinks = () => {
  const { logoURL } = useAppTheme();
  const extensionIdEl = document.getElementById("nl-extension-id");
  const extensionId = extensionIdEl ? extensionIdEl.value : null;

  return (
    <>
      <Link
        component={RouterLink}
        to="/"
        onClick={e => {
          if (extensionId) {
            e.preventDefault();
            chrome.runtime.sendMessage(extensionId, { type: NL_EXTENSION_EVENTS.NEW_LANTERN_WORKLIST_OPENED });
          }
        }}
      >
        {logoURL ? (
          <img src={logoURL} alt="logo" style={{ height: 30, width: "auto", verticalAlign: "middle" }} />
        ) : (
          <NewLanternLogoIcon style={{ height: 30, width: "auto", verticalAlign: "middle", fill: "white" }} />
        )}
      </Link>
      {NAV_SECTIONS.map(nav => (
        <Tooltip key={nav.title} title={nav.tooltip}>
          <Box ml={3}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={nav.route}
              onClick={e => {
                if (nav.route === "/" && extensionId) {
                  e.preventDefault();
                  chrome.runtime.sendMessage(extensionId, { type: NL_EXTENSION_EVENTS.NEW_LANTERN_WORKLIST_OPENED });
                }
              }}
            >
              {nav.title}
            </Link>
          </Box>
        </Tooltip>
      ))}
    </>
  );
};

export default LeftNavBarLinks;
