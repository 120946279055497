import React, { useState, ChangeEvent } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";

import { useMetaData } from "state/selector";
import { useApp } from "hooks/useApp";

const getRelevantModalities = (modalities: string[][]) => {
  const relevantModalities = ["CT", "MR", "XA", "CR", "DX", "PT", "NM", "US", "RF", "ECG", "SC", "MG", "RSTRUCT"];
  return modalities.filter(modality => relevantModalities.includes(modality[0]));
};

const ModalitySearchFilter = () => {
  const {
    navigation: { query, processChange },
  } = useApp();
  const metaData = useMetaData();

  const queryParam = "modalities";

  let defaultValue = query[queryParam] || [];
  if (typeof defaultValue === "string") {
    defaultValue = [defaultValue];
  }
  const [value, setValue] = useState(defaultValue);

  const processFieldChange = (newValue: string[]) => {
    processChange(queryParam, newValue);
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    let newValue = event.target.value;
    if (!Array.isArray(newValue)) {
      newValue = [event.target.value];
    }
    setValue(newValue as string[]);
    processFieldChange(newValue as string[]);
  };

  return (
    <FormControl size="small" variant="outlined" key="modalities">
      <Select
        data-testid="search-filter-dropdown"
        id="modalities"
        value={value}
        onChange={handleChange}
        MenuProps={{ className: "custom-dropdown-menu" }}
        multiple
      >
        {getRelevantModalities(metaData.modalities).map((choice: string[]) => (
          <MenuItem key={choice[0]} value={choice[0]}>
            {choice[0].toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ModalitySearchFilter;
