import React, { FC, useState, useEffect } from "react";
import { Button, Typography, IconButton, DialogTitle, DialogContent, DialogActions, Dialog } from "@material-ui/core";
import { Close, GetApp } from "@material-ui/icons";
import useStyles from "styles/patientJacket";
import { PatientJacketDialogStudyItem } from "components/StudyList/PatientJacket/PatientJacketDialogStudyItem";
import { getStudyListWithParams } from "api/study";
import { Patient } from "types/models/Patient";
import { Study } from "types/models/Study";
import { useAppTheme } from "providers/AppThemeProvider";

type PatientJacketDialogProps = {
  onClose: () => void;
  open: boolean;
  patient?: Patient;
};

export const PatientJacketDialog: FC<PatientJacketDialogProps> = ({ onClose, open, patient }) => {
  const classes = useStyles();
  const { theme } = useAppTheme();
  const [studyList, setStudyList] = useState<Study[]>([]);

  useEffect(() => {
    const getStudyList = async (patientId: string) => {
      const { results: studyListData } = await getStudyListWithParams({ patient_id: patientId, page_size: 30 });

      setStudyList(
        studyListData
          .sort((a, b) => b.time.localeCompare(a.time))
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      );
    };

    if (patient) {
      getStudyList(patient.id);
    }

    return () => {
      setStudyList([]);
    };
  }, [patient]);

  if (!patient) {
    return <></>;
  }

  return (
    <Dialog
      className={classes.patientJacketDialog}
      maxWidth="sm"
      fullWidth
      onClose={() => onClose()}
      open={open}
      data-testid="patient-jacket-dialog"
    >
      <DialogTitle>
        <Typography variant="h6" component="span" className={classes.title} data-testid="patient-jacket-title">
          Patient: {patient.name} [ Out | {patient.age} | {patient.sex} ]
        </Typography>
        <IconButton
          aria-label="close"
          data-testid="patient-jacket-close"
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
          onClick={() => onClose()}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers data-testid="patient-jacket-content">
        {studyList.map(study => (
          <PatientJacketDialogStudyItem study={study} key={study.id} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus>
          <GetApp />
          Download All Studies
        </Button>
      </DialogActions>
    </Dialog>
  );
};
