import { useEffect, useState, useMemo } from "react";
import { User } from "types/models/User";
import { getUsers } from "api/user";

export default function useUsers() {
  const [userList, setUserList] = useState<User[]>([]);

  useEffect(() => {
    const getUserList = async () => {
      const { results: users } = await getUsers();
      setUserList(users);
    };

    getUserList();

    return () => {
      setUserList([]);
    };
  }, []);

  const userFieldOptions = useMemo(
    () =>
      userList
        .map(u => ({
          label: u.name || u.email,
          value: u.id,
          user: u,
        }))
        .sort((u1, u2) => u1.label.localeCompare(u2.label)),
    [userList]
  );

  return { userList, userFieldOptions };
}
