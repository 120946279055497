import { makeStyles } from "@material-ui/core/styles";
import { AppTheme } from "types/AppTheme";

const useStyles = makeStyles((theme: AppTheme) => ({
  dialog: {
    "& .MuiDialog-container .MuiPaper-root": {
      maxWidth: 650,
      background: `${theme.palette.secondary.dark}`,
    },
    "& .MuiDialogTitle-root": {
      padding: "1.5em",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
      minHeight: "60vh",
      maxHeight: "60vh",
    },
    "& .MuiDialogActions-root": {
      padding: "1.5em",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.65em 1.5em 0.65em 1.5em",
    borderBottom: "1px solid rgba(94, 98, 103, 0.2)",
  },
  descriptions: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5%",
  },
  video: {
    width: "35%",
    "& iframe": {
      width: "100%",
      height: "auto",
    },
  },
  dialogTitle: {
    color: `${theme.palette.common.main}`,
    fontSize: "1.25rem",
  },
  title: {
    color: `${theme.palette.common.main}`,
    fontSize: "1rem",
    marginBottom: "0.2em",
  },
  titleLink: {
    textDecoration: "underline",
    textUnderlineOffset: "5px",
  },
  subTitle: {
    fontSize: "0.8rem",
    color: `${theme.palette.common.dark}`,
    marginTop: "0.1em",
  },
  spacing: {
    margin: "0 auto",
  },
}));

export default useStyles;
