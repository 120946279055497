import React, { FC } from "react";
import { getPredefinedColor } from "utils/users";
import { Avatar, Badge, Tooltip, withStyles, createStyles, Theme } from "@material-ui/core";
import userAutoCompleteEditStyles from "styles/userAutoCompleteEditStyles";
import { UserFieldOption } from "types/UserFieldOption";

type UserAutoCompleteOptionViewProps = {
  option: UserFieldOption;
  hideName?: boolean;
  showTooltip?: boolean;
};

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: (props: { online: boolean }) => (props.online ? "#44b700" : theme.palette.background.paper),
      color: (props: { online: boolean }) => (props.online ? "#44b700" : theme.palette.background.paper),
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  })
)(Badge);

export const UserAutoCompleteOptionView: FC<UserAutoCompleteOptionViewProps> = ({ option, hideName, showTooltip }) => {
  const classes = userAutoCompleteEditStyles();
  const { user } = option;

  const mainView = (
    <div className={classes.userOption} style={{ gap: "4px" }} data-testid="user-auto-complete">
      <StyledBadge
        data-testid="user-auto-complete-photo"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
        online={user.online}
      >
        <Avatar
          alt={user.name}
          src={user.profile_picture}
          style={{ width: 30, height: 30, background: getPredefinedColor(user.id) }}
        />
      </StyledBadge>
      {!hideName && (
        <div className={classes.userName} data-testid="user-auto-complete-name">
          {option.label}
        </div>
      )}
    </div>
  );

  return showTooltip ? <Tooltip title={option.label}>{mainView}</Tooltip> : mainView;
};
