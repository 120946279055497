import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography, IconButton, DialogTitle, DialogContent, Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import useStyles from "styles/recordingListDialogStyles";
import { getRecordingListWithParams } from "api/recording";
import { useAppTheme } from "providers/AppThemeProvider";

export default function RecordingListDialog({ study, onClose, open }) {
  const classes = useStyles();
  const [recordings, setRecordings] = useState([]);
  const { theme } = useAppTheme();

  useEffect(() => {
    async function getRecordings() {
      const { results } = await getRecordingListWithParams({
        study_id: study.id,
        ordering: "-created",
      });

      setRecordings(results);
    }

    if (study.id && open) {
      getRecordings();
    }
  }, [study.id, open]);

  return (
    <Dialog className={classes.dialog} maxWidth="sm" fullWidth onClose={() => onClose(false)} open={open}>
      <DialogTitle>
        <Typography variant="h6" component="span" className={classes.dialogTitle}>
          All Screen Recordings
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
          onClick={() => onClose(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {recordings.map((recording, index) => {
          const roomRecordingId = recording.url.split("/").pop();

          return (
            <div className={classes.row} key={index}>
              <div className={classes.video}>
                <iframe src={`https://www.loom.com/embed/${roomRecordingId}`} title="Loom Recording" />
              </div>
              <div className={classes.descriptions}>
                <span className={classes.title}>
                  <a href={recording.url} target="_blank" className={classes.titleLink} rel="noreferrer">
                    {recording.title}
                  </a>
                </span>
                <span className={classes.subTitle}>{moment(recording.created).format("DD MMM YYYY HH:mm a")}</span>
                <span className={classes.subTitle}>Author: {recording.author.username}</span>
              </div>
              <div className={classes.spacing} />
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
}
