import React, { lazy } from "react";

export const ROUTE_PATH_LIST: Array<{
  path: string;
  name: string;
}> = [
  { path: "/studies", name: "StudyList" },
  { path: "/studies/bookmark", name: "StudyBookmarkList" },
  { path: "/studies/history", name: "StudyHistoryList" },
  { path: "/hanging-protocols", name: "HangingProtocolList" },
  { path: "/hanging-protocols/:id", name: "HangingProtocolEdit" },
  { path: "/hanging-protocols/:id/clone", name: "HangingProtocolEdit" },
  { path: "/study/:id", name: "Study" },
  { path: "/report/:id", name: "Report" },
  { path: "/template/:id", name: "Template" },
  { path: "/dashboard", name: "Dashboard" },
  // { path: "/admin", name: "Admin" },
];

export const ROUTE_COMPONENT_LIST: {
  [key: string]: React.FC;
} = {
  StudyList: lazy(() => import("pages/StudyList/StudyList")),
  StudyBookmarkList: lazy(() => import("pages/StudyBookmarkList/StudyBookmarkList")),
  StudyHistoryList: lazy(() => import("pages/StudyHistoryList/StudyHistoryList")),
  Study: lazy(() => import("pages/Study/Study")),
  Report: lazy(() => import("pages/Report/Report")),
  HangingProtocolList: lazy(() => import("pages/HangingProtocolList/HangingProtocolList")),
  HangingProtocolEdit: lazy(() => import("pages/HangingProtocolEdit/HangingProtocolEdit")),
  Template: lazy(() => import("pages/Template/Template")),
  Dashboard: lazy(() => import("pages/Dashboard/Dashboard")),
};
