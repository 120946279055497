import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "state/reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const middleware = [
      ...getDefaultMiddleware({
        immutableCheck: false,
      }),
    ];

    if (process.env.NODE_ENV !== "production") {
      middleware.push(logger);
    }

    return middleware;
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
