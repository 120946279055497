import React, { useEffect } from "react";
import { Avatar, Box, Tooltip, Typography } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { getStudyHistoryList } from "state/reducers/studyHistory";
import { useStudyHistory } from "state/selector";
import { getPredefinedColor } from "utils/users";
import moment from "moment";
import { useAppTheme } from "providers/AppThemeProvider";

const CustomTimeText = time => {
  let text = "Last viewed ";
  const parsedTime = moment(time);
  const currTime = moment();

  if (currTime.diff(parsedTime, "minutes") < 10) {
    text += "now";
  } else {
    text += parsedTime.fromNow();
  }

  return text;
};

const Viewers = ({ study }) => {
  const dispatch = useDispatch();
  const { theme } = useAppTheme();

  useEffect(() => {
    dispatch(getStudyHistoryList({ study_id: study.id }));
  }, [dispatch, study.id]);

  const { list: studyViewers, count, isLoading } = useStudyHistory();

  if (isLoading || count === 0) return <></>;

  return (
    <>
      <AvatarGroup>
        {studyViewers.slice(0, count > 4 ? 3 : 4).map((viewer, index) => (
          <Tooltip
            key={viewer.id}
            title={
              <Box>
                <Typography variant="caption" style={{ fontWeight: 700, display: "block", marginBottom: "-4px" }}>
                  {viewer.historyUser}
                </Typography>
                <Typography variant="inherit">{CustomTimeText(viewer.historyAccessed)}</Typography>
              </Box>
            }
          >
            <Avatar
              src={viewer.historyUserProfile}
              alt={viewer.historyUser}
              style={{
                width: 30,
                height: 30,
                zIndex: count - index,
                opacity: index === 0 ? 1 : 0.5,
                background: getPredefinedColor(viewer.historyUserId),
              }}
            />
          </Tooltip>
        ))}
        {count > 4 && (
          <Tooltip
            title={
              <Box sx={{ display: "flex", flexDirection: "column", paddingY: "4px" }} style={{ gap: "12px" }}>
                {studyViewers.slice(3).map(viewer => (
                  <Box sx={{ display: "flex", alignItems: "center" }} style={{ gap: "6px" }}>
                    <Avatar
                      src={viewer.historyUserProfile}
                      alt={viewer.historyUser}
                      style={{
                        width: 30,
                        height: 30,
                        background: getPredefinedColor(viewer.historyUserId),
                      }}
                    />
                    <Box>
                      <Typography variant="caption" style={{ fontWeight: 700, display: "block", marginBottom: "-4px" }}>
                        {viewer.historyUser}
                      </Typography>
                      <Typography variant="inherit">{CustomTimeText(viewer.historyAccessed)}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            }
          >
            <Avatar
              style={{
                width: 30,
                height: 30,
                zIndex: 0,
                opacity: 0.5,
                background: theme.palette.secondary.dark,
                color: theme.palette.primary.light,
              }}
            >
              <Typography variant="body2">+{count - 3}</Typography>
            </Avatar>
          </Tooltip>
        )}
      </AvatarGroup>
    </>
  );
};

export default Viewers;
