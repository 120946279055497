import React, { useEffect } from "react";
import { useAuth } from "state/selector";
import { getUser } from "state/reducers/auth";
import { useAppDispatch } from "state/dispatcher";
import { sendExtensionMessage } from "utils/extension";
import { NL_EXTENSION_EVENTS } from "utils/constants";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }: AuthGuardProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user && user.clerk_id !== "") {
      const delay = setTimeout(() => {
        sendExtensionMessage({ type: NL_EXTENSION_EVENTS.NEW_LANTERN_SET_USER, id: user.clerk_id });
      }, 1000);

      return () => {
        clearTimeout(delay);
      };
    }
  }, [user]);

  return <>{user && children}</>;
};

export default AuthGuard;
