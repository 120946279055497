import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

const ProfileModal = ({ openPopup, maxWidth, children }) => (
  <div>
    <Dialog
      open={openPopup}
      fullWidth
      maxWidth={maxWidth || "md"}
      color="primary"
      PaperProps={{
        style: {
          backgroundColor: "#161f32",
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  </div>
);

export default ProfileModal;
