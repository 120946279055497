import { PaginationParams } from "types/pagination/PaginationParams";
import { HangingProtocol } from "types/models/HangingProtocol";
import { PaginationBody } from "types/pagination/PaginationBody";
import api from "api/api";

export const getHangingProtocolListWithParams = async (params: PaginationParams) => {
  const { data } = await api("/api/hanging-protocol/", { params });
  return data as PaginationBody<HangingProtocol>;
};

export const getHangingProtocolById = async (hangingProtocolId: number) => {
  const { data } = await api.get(`/api/hanging-protocol/${hangingProtocolId}`);
  return data as HangingProtocol;
};

export const createHangingProtocol = async (payload: HangingProtocol) => {
  const { data } = await api.post("/api/hanging-protocol/", payload);
  return data as HangingProtocol;
};

export const updateHangingProtocol = async (hangingProtocolId: number, payload: Partial<HangingProtocol>) => {
  const { data } = await api.put(`/api/hanging-protocol/${hangingProtocolId}/`, payload);
  return data as HangingProtocol;
};

export const deleteHangingProtocol = async (hangingProtocolId: number) => {
  await api.delete(`/api/hanging-protocol/${hangingProtocolId}/`);
  return { success: true };
};
