import * as Sentry from "@sentry/react";
import { AnyObject } from "types/AnyObject";
import { User } from "types/models/User";

class LoggerService {
  formatText(text: string) {
    return `${new Date().toISOString()}: ${text}`;
  }

  setUser(user: User) {
    const data: AnyObject = {};

    if (user?.email) {
      data.email = user.email;
    }

    if (user?.id) {
      data.id = user.id;
    }

    if (user?.username) {
      data.username = user.username;
    }

    Sentry.setUser(user);
  }

  error(err: string) {
    Sentry.captureException(err);
    console.error(this.formatText(err));
  }

  info(message: string, data = "") {
    console.info(this.formatText(message), data);
  }

  warning(message: string, data = "") {
    console.warn(this.formatText(message), data);
  }
}

export const logger = new LoggerService();
