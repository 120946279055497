import api from "api/api";
import { InteropToken, LoomToken } from "types/models/token";

export const getInteropToken = async () => {
  const { data } = await api.get(`/api/interop/token`);
  return data as InteropToken;
};

export const getLoomToken = async () => {
  const { data } = await api.get(`/api/loom/token`);
  return data as LoomToken;
};
