export function convertOpacityIntoHexString(opacity: number) {
  return Math.floor(opacity * 255).toString(16);
}

export function hexToRgb(hex: string): string {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return "";
  }

  const rColor = parseInt(result[1], 16);
  const gColor = parseInt(result[2], 16);
  const bColor = parseInt(result[3], 16);

  return `rgb(${rColor}, ${gColor}, ${bColor})`;
}
