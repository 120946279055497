import { PaginationParams } from "types/pagination/PaginationParams";
import { Bookmark } from "types/models/Bookmark";
import { PaginationBody } from "types/pagination/PaginationBody";
import api from "api/api";

export const addBookmark = async (payload: Partial<Bookmark>): Promise<Bookmark> => {
  const { data } = await api.post(`/api/bookmarks/`, payload);
  return data as Bookmark;
};

export const deleteBookmark = async (id: number) => {
  await api.delete(`/api/bookmarks/${id}/`);
  return { success: true };
};

export const getBookmarks = async (params: PaginationParams) => {
  const { data } = await api.get(`/api/bookmarks/`, { params });
  return data as PaginationBody<Bookmark>;
};
