import React, { forwardRef } from "react";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
  Search,
  ArrowForwardIos,
  ArrowBackIos,
  PhotoLibrary,
} from "@material-ui/icons";

type SVGElementProps = {
  fontSize: "default" | "inherit" | "large" | "medium" | "small";
};

export const TABLE_ICONS = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const SEARCH_FILTER_ICONS = {
  ResetSearch: forwardRef<SVGSVGElement, SVGElementProps>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement, SVGElementProps>((props, ref) => <Search {...props} ref={ref} />),
  ArrowForwardIos: forwardRef<SVGSVGElement, SVGElementProps>((props, ref) => <ArrowForwardIos {...props} ref={ref} />),
  ArrowBackIos: forwardRef<SVGSVGElement, SVGElementProps>((props, ref) => <ArrowBackIos {...props} ref={ref} />),
};

export const PhotoIcon = ({ color }: { color: string }) => (
  <PhotoLibrary style={{ color, width: "0.85em", height: "0.85em", marginRight: "0.15em" }} />
);
