import React, { FC, useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import moment from "moment";
import { useInterval } from "react-use";
import { useAppTheme } from "providers/AppThemeProvider";

type LastUpdatedProps = {
  lastUpdatedTime: number | null;
  onRefresh: () => void;
  autoUpdateInterval?: number;
};

export const LastUpdated: FC<LastUpdatedProps> = ({ lastUpdatedTime, onRefresh, autoUpdateInterval = 30000 }) => {
  const [sinceUpdate, setSinceUpdate] = useState(moment(lastUpdatedTime).fromNow());
  const { theme } = useAppTheme();

  useInterval(() => setSinceUpdate(moment(lastUpdatedTime).fromNow()), 10000);
  useInterval(() => onRefresh(), autoUpdateInterval);

  return (
    <Box
      sx={{ color: theme.palette.common.bright, display: "flex", alignItems: "center", marginLeft: "16px" }}
      data-testid="last-updated"
    >
      <Box data-testid="last-updated-text" sx={{ whiteSpace: "nowrap" }}>
        Last updated {sinceUpdate}
      </Box>
      <IconButton onClick={() => onRefresh()} data-testid="last-updated-refresh">
        <Refresh />
      </IconButton>
    </Box>
  );
};
